import React from "react";
import {
  Button,
  Popover,
  IconButton,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import rgbConverter from "../../../utils/rgbConverter";

export default function Contact() {
  const contactEmail = process.env.REACT_APP_CONTACT_EMAIL_ADDRESS;
  const contactSubject = process.env.REACT_APP_CONTACT_EMAIL_SUBJECT;
  const contactTooltip = process.env.REACT_APP_CONTACT_EMAIL_TOOLTIP;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (

    <Tooltip title={contactTooltip} arrow>
     <Box>

      <IconButton onClick={handleClick}>
        <MailOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box padding="15px">
          <Typography color="textSecondary" variant="body2" margin="normal">
            Contact Administrator
          </Typography>
          <Button
            variant="outlined"
            style={{ width: "calc(100% - 10px)", margin: 5 }}
            href={"mailto:" + contactEmail + "?subject=" + contactSubject}
            color="primary"
            margin="normal"
          >
            Email
          </Button>
        </Box>
      </Popover>
    </Box>
    </Tooltip>
  );
}
