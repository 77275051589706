import { createStyles, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles(theme => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: theme.palette.background.dark,
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    iframe: {
      border: '0px'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiCardContent-root': {
      "&:last-child": {
        paddingBottom: '16px !important'
      }
    },
    '.MuiTypography-body1': {
      fontSize: '14px !important'
    },
    '.MuiCheckbox-root': {
      color: theme.palette.primary.greyColor
    },
    '.errorMessage': {
      color: theme.palette.secondary.red,
      fontSize: 24
    },
    '.fabProgress': {
      color: theme.palette.primary.main,
      marginTop: '20%',
      marginLeft: '35%',
      marginBottom: '15%'
    } , 
    '.MuiDrawer-paperAnchorRight':{
      width: "850px",
  }
  
   
  }
}));

const GlobalStyles = () => {
  const theme = useTheme();

  useStyles(theme);

  return null;
};

export default GlobalStyles;
